import { logError } from '@web/utils/error';
import { NativeHelper } from '@web/data/native.helper';
import { useCallback, useState } from 'react';
import { NativeMessage } from '@global/utils/native/native-message';

/** https://developers.facebook.com/docs/messenger-platform/reference/messenger-extensions-sdk */
declare let MessengerExtensions;

interface useCloseMessengerModalCallbackResponse {
  close: () => void;
  loading: boolean;
}

/**
 * This hook is supposed to be used inside Messenger webview or ReactNativeWebVIew.
 * It will send a command to close the modal
 */
export const useCloseMessengerModalCallback = (): useCloseMessengerModalCallbackResponse => {
  const [loading, setLoading] = useState(false);
  const close = useCallback(() => {
    if (NativeHelper.isNative) {
      const message: NativeMessage = { type: 'close' };
      setLoading(true);
      return NativeHelper.postMessage(message);
    }

    if (typeof MessengerExtensions !== 'undefined') {
      MessengerExtensions.requestCloseBrowser(
        (success) => {
          console.log('MessengerExtensions.requestCloseBrowser -> success', success);
        },
        (error) => {
          console.error('ERROR: MessengerExtensions.requestCloseBrowser -> error', error);
          // https://developers.facebook.com/docs/messenger-platform/webview/#redirect
          window.location.replace(
            'https://www.messenger.com/closeWindow/?image_url=https%3A%2F%2Fmedia.giphy.com%2Fmedia%2FmQpZtX0gKDESA%2Fgiphy.gif%0A&display_text=feche%20essa%20janela'
          );
          logError(error);
        }
      );
      return;
    }
  }, []);

  return { close, loading };
};
