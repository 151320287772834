import { Col, Grid, Row, Separator } from '@web/atomic';
import { useCloseMessengerModalCallback } from '@web/atomic/obj.custom-hooks/close-messenger-modal.hook';
import { AssesmentReport } from '@lp-root/src/modules/assesment/assesment-report.component';
import { hasWindow } from '@web/utils/platform';
import { getCanonicalUrl } from '@web/utils/url';
import { graphql } from 'gatsby';
import QueryString from 'querystringify';
import * as React from 'react';
import { SEO } from '../../components/legacy/mol.seo/seo.component';
import WebviewLayout from '../../components/org.layout/webview-layout.component';
import { GatsbyButton } from '@components/atm.button/button.component';
import { PageProps } from '../../utils/local-types';
import { SleepProblemCause, SleepProblemAge } from '@lp-root/src/data/quiz-typeform.model';

interface DicaSessao1PageQueryParams {
  age?: number;
  tip?: '1' | '2' | '3' | '4' | '5';
}

const DicaSessao1Page: React.FunctionComponent<PageProps> = (props) => {
  const [config, setConfig] = React.useState({
    age: null,
    cause: null,
  });

  React.useEffect(() => {
    const params: DicaSessao1PageQueryParams = QueryString.parse(hasWindow() ? window.location.search : '');
    setConfig({
      age: getAge(params),
      cause: getTip(params),
    });
  }, []);

  const { close, loading } = useCloseMessengerModalCallback();
  return (
    <WebviewLayout>
      <SEO
        socialMedia={{
          canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
          title: 'Orientações da 1ª sessão',
          description: 'Descubra mais sobre sua insônia e uma dica para aplicar hoje!',
        }}
      />
      <Grid>
        {config && config.age && config.cause && (
          <Row center={'xs'} mb mt>
            <Col xs={12} sm={8}>
              {config && config.age && config.cause && <AssesmentReport assesment={config} showSeeMore={true} cta={null} />}
            </Col>
          </Row>
        )}
        {config && config.age && config.cause && (
          <Row center={'xs'}>
            <Col xs={12} md={7}>
              <GatsbyButton onClick={close} loading={loading} expanded>
                Fechar
              </GatsbyButton>
              <Separator />
            </Col>
          </Row>
        )}
      </Grid>
    </WebviewLayout>
  );
};

export const query = graphql`
  query DicaSessao1Page {
    site {
      ...SiteUrl
    }
  }
`;

export default DicaSessao1Page;

////////////////////////////////////////////////////////////////////////////////////////////////////
function getTip(params: DicaSessao1PageQueryParams) {
  const tip = params.tip;
  let formattedTip: SleepProblemCause;
  switch (tip) {
    case '1':
      formattedTip = SleepProblemCause.FutureWorry;
      break;
    case '2':
      formattedTip = SleepProblemCause.NotSleepingWorry;
      break;
    case '3':
      formattedTip = SleepProblemCause.PhysicalPain;
      break;
    case '4':
      formattedTip = SleepProblemCause.Noise;
      break;
    case '5':
      formattedTip = SleepProblemCause.Light;
      break;
    default:
      console.error('ERRROR: DicaSessao1Page: Invalid value for tip: ', tip);
      break;
  }
  return formattedTip;
}

function getAge(params: DicaSessao1PageQueryParams) {
  const age = params.age;
  let formattedAge: SleepProblemAge;
  if (age < 1) {
    formattedAge = SleepProblemAge.LessOneMonth;
  } else if (age < 3) {
    formattedAge = SleepProblemAge.BetweenOneAndThree;
  } else if (age <= 6) {
    formattedAge = SleepProblemAge.BetweenThreeAndSix;
  } else if (age <= 12) {
    formattedAge = SleepProblemAge.BetweenSixAndTwelve;
  } else {
    formattedAge = SleepProblemAge.MoreThanOneYear;
  }
  return formattedAge;
}
