import { AppThemeContext } from '@web/atomic';
import React, { useContext } from 'react';
import ReactSwitch from 'react-switch';

export const ThemeSwitch: React.FunctionComponent = () => {
  const { theme, toggle } = useContext(AppThemeContext);
  return (
    <ReactSwitch
      checked={theme.name === 'regular'}
      onChange={toggle}
      onColor={theme.color.primary}
      uncheckedIcon={
        <span aria-label="moon" role="img" style={{ display: 'flex', lineHeight: '29px', justifyContent: 'center', alignItems: 'center' }}>
          🌙
        </span>
      }
      checkedIcon={
        <span aria-label="sun" role="img" style={{ display: 'flex', lineHeight: '29px', justifyContent: 'center', alignItems: 'center' }}>
          ☀️
        </span>
      }
    />
  );
};
