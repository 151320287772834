import { NativeHelper } from '@web/data/native.helper';
import { Col, Grid, Row } from '@web/atomic';
import { ThemeSwitch } from '@web/atomic/atm.theme-switch/theme-switch.component';
import { FlashWrapper } from '@web/atomic/legacy/obj.flash-wrapper/flash-wrapper.component';
import { messengerExtensionSdkUrl } from '@web/data/messenger-user-info.datasource';
import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet';

interface WebviewLayoutProps {
  children: ReactNode;
  hideThemeChanger?: boolean;
  skipMessengerExtensionLoad?: boolean;
}

const WebviewLayout: React.FunctionComponent<WebviewLayoutProps> = ({ children, hideThemeChanger }) => {
  // why? https://tableless.com.br/declarando-idiomas-no-html/
  return (
    <>
      {NativeHelper.isNative || (
        <Helmet>
          <script src={messengerExtensionSdkUrl} async />
        </Helmet>
      )}
      <FlashWrapper />
      {hideThemeChanger || (
        <Grid>
          <Row mt end="xs">
            <Col>
              <ThemeSwitch />
            </Col>
          </Row>
        </Grid>
      )}
      {children}
    </>
  );
};

export default WebviewLayout;
